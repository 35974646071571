import React from 'react'
import HeaderPaginas from '../../../components/headerPaginas'
import Layout from '../../../components/layout'
import Seo from '../../../components/SEO/SEO'
import CtaServicios from '../../../components/ctaServicios'

export default function Outsourcing(props) {
    return (
        <Layout>
            <Seo title="Outsourcing tecnológico" pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Outsourcing tecnológico" imagen="/img/cabeceras/outosurcing_sistemas_bullhost.jpg" />
                <div className="intro-servicio">
                    <p>El outsourcing tecnológico es una gran idea. En los últimos años, el crecimiento de empresas que externalizan la gestión de sus infraestructuras informáticas y tecnologías de la información ha sido imparable.</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <section className="limitador">
                    <h2>Ventajas del outsourcing informático</h2>
                    <div className="fila-cards-cpd-propio-caracteristicas">
                        <div className="card-cpd-propio-caracteristicas">
                            <div className="col-imagen" style={{ backgroundImage: "url(/img/outsourcing-informatico-1.jpg)" }}>
                                {/* <StaticImage src="../../../static/img/bullhost-cloud-robusto-cpd.jpg" alt="Cloud robusto" /> */}
                            </div>
                            <div className="col-texto">
                                <div>
                                    <h3>Tu equipo se centrará en tu core de negocio</h3>
                                    <p>Evita que tu equipo se disperse en tareas para las que no están preparados. Zapatero a tus zapatos: confía en un colaborador externo cualificado para gestionar tus sistemas.</p>
                                </div>
                                <div>
                                    <h3>Evitas pérdidas de tiempos de aprendizaje y de inactividad</h3>
                                    <p>El acceso a un conocimiento especializado es complicado y requiere de esfuerzo y tiempo. Además, evitarás problemas de conexión, datos corrompidos, errores de seguridad, correo electrónico mal configurado o falta de sistemas de backup y recuperación tras ataques.</p>
                                </div>
                                <div>
                                    <h3>Ahorrarás costes en personal especializado</h3>
                                    <p>Los servicios de outsourcing informático y subcontratación de personal de Bullhost resultarían demasiados caros de obtener de forma directa. Como media, las PyMES ahorran gracias al outsourcing hasta un 50% más que contratando a profesionales de IT especializados.</p>
                                </div>
                                <div>
                                    <h3>Aumentarás la productividad</h3>
                                    <p>Un outsourcing bien gestionado aumenta la productividad y los beneficios de cualquier empresa. Implementar correctamente la tecnología evita fallos e incidencias que ralentizan y encarecen los procesos de negocio.</p>
                                </div>
                                <div>
                                    <h3>Innovarás por delante de la competencia</h3>
                                    <p>La externalización ayuda a tomar ventaja en los procesos de adaptación de las nuevas tecnologías. Lo que supone ponerse por delante de la competencia, agilizar empresas, y, en última instancia, colaborar en que sean más competitivas en un mercado cada vez más complejo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="limitador">
                        <h2>¿Cómo es nuestro outsourcing?</h2>
                    <div className="fila-cards-cpd-propio-caracteristicas">
                        <div className="card-cpd-propio-caracteristicas">
                            <div className="col-texto">
                                <div>
                                    <h3>Continuidad de la persona</h3>
                                    <p>El técnico asignado tendrá siempre continuidad en el mismo, salvo situaciones de fuerza mayor (vacaciones, baja por enfermedad, u otros..) o por exigencia del personal del cliente.</p>
                                </div>
                                <div>
                                    <h3>En equipo</h3>
                                    <p>El técnico NO está sólo. Cuando contratas un servicio en outsourcing con nosotros no contratas únicamente a un técnico, sino a todo un equipo profesional que está detrás y a unas infraestructura propias que te aportan seguridad cuando más lo necesites.</p>
                                </div>
                                <div>
                                    <h3>Supervisión y coordinación</h3>
                                    <p>La coordinación y control de las tareas que ejecute el técnico se supervisarán y consensuarán con los consultores tecnológicos y service manager de Bullhost.</p>
                                </div>
                                <div>
                                    <h3>Ahorrar en recursos no significa ahorrar en derechos.</h3>
                                    <p>NO somos una ETT ni una consultora “cárnica”. Los profesionales que asignamos a las empresas son trabajadores estables que forman parte del equipo y del proyecto Bullhost. Si trabajan a gusto, todos salimos ganando.</p>
                                </div>
                            </div>
                            <div className="col-imagen" style={{ backgroundImage: "url(/img/outsourcing-informatico-2.jpg)" }}>
                                {/* <StaticImage src="../../../static/img/bullhost-cloud-robusto-cpd.jpg" alt="Cloud robusto" /> */}
                            </div>
                        </div>
                    </div>
                </section>
                </div>
                <CtaServicios/>
        </Layout>
    )
}
